import React from 'react';
import './App.css';
import rawImage from './image/raw.jpg';
import flameImage from './image/flame.jpg';
import grillImage from './image/grill.jpg';

const AboutPage = () => {
    return (
        <div className="about-page-container">
            <div className="intro-container">
                <div className="intro-title">
                    <span className="about">ABOUT</span>
                    <span className="us-container">
                        <span className="us">US</span>
                    </span>
                </div>
                <p className="intro-description">
                RFG Restaurant, Raw Flame Grill Restaurant, is a modern French dining venue that focuses on showcasing the best seasonal ingredients through a curated 8-course tasting menu.   
                </p>
                
                <p className="intro-description">
                Our passion for vintage wines run deed. We take pride in not only collecting, but sharing these exceptional wines from around the world. Whether you’re a wine connoisseur or simply curious, our selectional is sure to enhance your culinary journey. Join us at RFG to explore our ever growing wine list! 
                </p>

                <p className="intro-description">
                At RFG, we also specialize in catering to events and private functions, where we collaborate closely with guests to offer either a personalised menu or a selection from our menus. Whether you are celebrating a special occasion or hosting a private event, we are committed to delivering an unforgettable, tailored experience for you and your party! 
                </p>
            </div>
            <div className="centered-container">
                <div className="about-section left">
                    <div className="text-content">
                        <h2 className="section-title">RAW</h2>
                        <p className="section-description">Preparing fresh, high-quality seafood, meats, and delicacies from the beautiful New Zealand waters and farms.</p>
                    </div>
                    <div className="image-content">
                        <img src={rawImage} alt="Raw" className="section-image" />
                    </div>
                </div>
                <div className="about-section right">
                    <div className="text-content">
                        <h2 className="section-title">FLAME</h2>
                        <p className="section-description">Mastering the art of open flame cooking to bring out the natural flavors of premium ingredients.</p>
                    </div>
                    <div className="image-content">
                        <img src={flameImage} alt="Flame" className="section-image" />
                    </div>
                </div>
                <div className="about-section left">
                    <div className="text-content">
                        <h2 className="section-title">GRILL</h2>
                        <p className="section-description">A variety of high-quality meats and vegetables, grilled to perfection for an exquisite taste experience.</p>
                    </div>
                    <div className="image-content">
                        <img src={grillImage} alt="Grill" className="section-image" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;







